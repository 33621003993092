import { yupResolver } from "@hookform/resolvers/yup";
import Notiflix, { Confirm } from "notiflix";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useAddBoard, useDeleteBoard, useEditBoard, useGetBoardInfo } from "../../apis/boardApi";
import { CardTail, CardTitle } from "../../components/layout/CardLayout/CardLayout";
import TextEditor from "../../elements/Editor/TextEditor";
import { FormControlsBox } from "../../elements/Form/FormLayout";
import { ErrorMessage, NotiMessage } from "../../elements/Message/MessageText";
import { IK } from "../../utils/i18n/keyStore";
import BoardPostSkeleton from "./BoardPostSkeleton";

//스타일 관련
import * as S from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import * as S2 from "../../elements/Form/style/Form.style";
import { usePermissionCheck } from "../../hooks/usePermissionCheck";
import CountryBtn from "../country/CountryBtn";
import SelectedCountryList from "../country/SelectedCountryList";

/**보드게시판
 * @param {string} boardTitle - 게시판 제목
 * @param {string} boardSortCode - 게시판 코드
 * @param {string} boardId - 게시판 아이디
 * @param {string} navi - 이동경로
 * @param {boolean} video - 비디오 등록 여부
 * @param {boolean} attachFile - 첨부파일 등록 여부
 */

const BoardPostBox = ({ boardTitle, boardSortCode, navi = "/", video, attachFile }) => {
  const { boardId } = useParams();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const location = useLocation();

  /**권한 조회 */
  const boardAuth = usePermissionCheck("boardList");

  /**기존 게시판 데이터 받아오기 */
  const { data: editBoardInfo, isLoading } = useGetBoardInfo(boardId);

  /**유효성 검사 */
  const formSchema = Yup.object({
    title: Yup.string().required(t(IK.event_title_required)),
    countryCodes: Yup.array().when("$countryCode", {
      is: () => boardAuth?.isAll,
      then: () => Yup.array().required(t(IK.country_required)).min(1, t(IK.country_required)),
      otherwise: () => Yup.array().notRequired(),
    }),
    thumb: Yup.mixed().required(t(IK.board_thumb_required)),
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const boardWatch = watch();

  /**수정시 기존 데이터 셋팅 */
  useEffect(() => {
    if (!isLoading && editBoardInfo) {
      const { countryCodes, title, content, boardFiles } = editBoardInfo;
      const { thumb, attach } = editBoardInfo?.boardFiles;
      if (attach) {
        video ? setValue("attach", boardFiles?.attach[0]) : setSelectedFiles([...attach]);
      }
      thumb && setValue("thumb", thumb[0]);
      setValue("title", title);
      setValue("content", content);
      if (countryCodes?.length > 0) setValue("countryCodes", countryCodes);
    }
  }, [isLoading, editBoardInfo, setValue, video]);

  const addBoardMutation = useAddBoard();
  const editBoardMutation = useEditBoard();
  const deleteMutation = useDeleteBoard();

  /**첨부파일 등록 */
  const [selectedFiles, setSelectedFiles] = useState([]);

  /**아이디 값 추가하여 첨부파일 등록 */
  const handleFileChange = (event) => {
    const files = event.target.files;
    const timeId = Math.floor(Date.now() / 1000); // 고유한 아이디값 부여
    const updatedFiles = Array.from(files).map((file, index) => ({
      boardFileId: file.boardFileId || `${index}_${timeId}`,
      file: file,
    }));
    setSelectedFiles((prev) => [...prev, ...updatedFiles]);
  };

  /**파일 삭제 아이디 모음 */
  const [deleteFileIds, setDeleteFileIds] = useState([]);

  /**첨부 파일 삭제 */
  const handleFileDelete = (boardFileId) => {
    if (typeof boardFileId === "number") {
      //기존 파일인 경우 삭제목록에 추가
      setDeleteFileIds((prev) => [...prev, boardFileId]);
    }
    setSelectedFiles(selectedFiles.filter((fileObj) => fileObj.boardFileId !== boardFileId));
  };

  /**썸네일 등록 */
  const handleThumbChange = (event) => {
    setValue("thumb", event.target.files[0]);
    const existingThumb = editBoardInfo?.boardFiles?.thumb;
    if (existingThumb) {
      //새로운 썸네일 등록시 기존 썸네일 파일 삭제
      setDeleteFileIds((prev) => [...prev, existingThumb[0].boardFileId]);
    }
  };

  /**비디오등록*/
  const handleVideoChange = (event) => {
    //mp4 파일이 아니라면 경고
    if (event.target?.files[0]?.type !== "video/mp4") {
      return Notiflix.Report.warning(t(IK.video_warning_mp4), "", t(IK.confirm));
    }

    setValue("attach", event.target.files[0]);
    const existingAttach = editBoardInfo?.boardFiles?.attach;
    if (existingAttach) {
      //새비디오등록시 기존 비디오 파일 삭제
      setDeleteFileIds((prev) => [...prev, existingAttach[0].boardFileId]);
    }
  };

  /**데이터 전송 */
  const submitContent = (data) => {
    const formData = new FormData();
    const submitData = { ...data, deleteBoardFileIds: deleteFileIds };

    // 첨부파일 추가
    if (selectedFiles.length > 0) {
      selectedFiles.forEach((value) => {
        if (typeof value.boardFileId === "string") {
          //새로 추가된 파일만 등록
          formData.append("attach", value.file);
        }
      });
    }

    //데이터 셋팅
    Object.entries(submitData).forEach(([key, value]) => {
      //빈값이 아닌 경우
      if (value && !(Array.isArray(value) && value.length === 0)) {
        //이미 파일로 등록된 경우 제외
        if (!((key === "attach" || key === "thumb") && value.fileId)) {
          formData.append(key, value);
        }
      }
    });

    if (boardId) {
      editBoardMutation.mutate(
        { boardId, formData },
        {
          onSuccess: () => {
            Notiflix.Report.success("", t(IK.update_success), t(IK.confirm), () => navigate(navi));
          },
        }
      );
    } else {
      formData.append("boardSortCode", boardSortCode);
      addBoardMutation.mutate(formData, {
        onSuccess: () => {
          Notiflix.Report.success("", t(IK.add_success), t(IK.confirm), () => navigate(navi));
        },
      });
    }
  };

  /**게시글 삭제 */
  const onDelete = () => {
    Confirm.show(t(IK.warning_delete_1), t(IK.warning_delete_2), t(IK.confirm), t(IK.cancel), () => {
      deleteMutation.mutate(boardId, {
        onSuccess: () => {
          Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => navigate(navi));
        },
      });
    });
  };

  const fileInputRef = useRef(null);
  /**게시글 동영상 파일 삭제 */
  const handleVideoDelete = () => {
    // 입력 필드 값 초기화
    setValue("attach", "");
    // 기존 파일 삭제 처리
    const boardFileId = editBoardInfo?.boardFiles?.attach?.[0]?.boardFileId;
    if (boardFileId && !deleteFileIds.includes(boardFileId)) {
      setDeleteFileIds((prev) => [...prev, boardFileId]);
    }
    // 파일 선택 input 초기화
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // input 값 비우기
    }
  };

  return (
    <>
      <S.CardRow>
        <S.CardCol>
          <S.CardInner>
            <CardTitle title={t(IK[boardTitle])} txt />
            {isLoading ? (
              <BoardPostSkeleton />
            ) : (
              <form onSubmit={handleSubmit(submitContent)}>
                <S2.FormControlsContainer>
                  {/* 국가 선택 */}
                  {boardAuth?.isAll && (
                    <>
                      <FormControlsBox required title={t(IK.country)}>
                        <CountryBtn setValue={setValue} clearErrors={clearErrors} watch={boardWatch?.countryCodes} />
                        <ErrorMessage errors={errors} name="countryCodes" />
                      </FormControlsBox>
                      {boardWatch?.countryCodes?.length > 0 && (
                        <FormControlsBox required title={`${t(IK.country)} ${t(IK.list)}`}>
                          <SelectedCountryList watch={boardWatch.countryCodes} setValue={setValue} t={t} />
                        </FormControlsBox>
                      )}
                    </>
                  )}
                  {/* 게시판 제목 */}
                  <FormControlsBox required title={t(IK.board_title)}>
                    <S2.FormControls {...register("title")} defaultValue={editBoardInfo?.title || ""} disabled={!boardAuth?.isAll} />
                    <ErrorMessage errors={errors} name="title" />
                  </FormControlsBox>

                  {/* 게시판 내용 */}
                  <FormControlsBox title={t(IK.board_content)}>
                    <TextEditor setData={(content) => setValue("content", content)} content={editBoardInfo?.content} disabled={!boardAuth?.isAll} />
                  </FormControlsBox>

                  {/* 게시판 썸네일 */}
                  <FormControlsBox required title={t(IK.board_thumb)}>
                    <S2.FormControlsFlex>
                      <S2.FormControls disabled value={boardWatch?.thumb?.orgFileName || boardWatch?.thumb?.name || ""} />
                      <S2.FormControls type="file" id="thumb" name="thumb" accept="image/*" onChange={handleThumbChange} hidden disabled={!boardAuth?.isAll} />
                      <S2.FormControlsBtn as="label" htmlFor="thumb">
                        {t(IK.file_btn)}
                      </S2.FormControlsBtn>
                    </S2.FormControlsFlex>
                    {!boardWatch.thumb && <ErrorMessage errors={errors} name="thumb" />}
                    <NotiMessage text={`${t(IK.recommended_size)} 288 x 179`} />
                  </FormControlsBox>

                  {/* 게시판 비디오(더 잘하기) */}
                  {video && (
                    <FormControlsBox title={t(IK.video_limit)}>
                      <S2.FormControlsFlex>
                        <S2.FormControls disabled value={boardWatch?.attach?.orgFileName || boardWatch.attach?.name || ""} />
                        <S2.FormControls type="file" id="attach" name="attach" accept="video/mp4" onChange={handleVideoChange} hidden disabled={!boardAuth?.isAll} ref={fileInputRef} />
                        <S2.FormControlsBtn as="label" htmlFor="attach">
                          {t(IK.file_btn)}
                        </S2.FormControlsBtn>
                        <S3.StyledButton as="button" $secondary type="button" onClick={handleVideoDelete} disabled={!boardAuth?.isAll}>
                          {t(IK.delete)}
                        </S3.StyledButton>
                      </S2.FormControlsFlex>
                      {!boardWatch.attach && <ErrorMessage errors={errors} name="attach" />}
                    </FormControlsBox>
                  )}

                  {/*게시판 첨부파일(자료실) */}
                  {attachFile && (
                    <>
                      <FormControlsBox title={t(IK.attached_file)}>
                        <S2.FormControlsFlex>
                          <input id="bordFile" type="file" hidden multiple onChange={handleFileChange} disabled={!boardAuth?.isAll} />
                          <S2.FormControlsBtn as="label" htmlFor="bordFile">
                            {t(IK.file_btn)}
                          </S2.FormControlsBtn>
                        </S2.FormControlsFlex>
                      </FormControlsBox>
                      {selectedFiles.length > 0 && (
                        <FormControlsBox title={`${t(IK.attached_file)} ${t(IK.list)}`}>
                          <S2.FormFileList>
                            {selectedFiles.map((item) => (
                              <li key={item.boardFileId}>
                                {item.file?.name || item.orgFileName}
                                <button onClick={() => handleFileDelete(item.boardFileId)} disabled={!boardAuth?.isAll}>
                                  <i className="ri-close-line"></i>
                                </button>
                              </li>
                            ))}
                          </S2.FormFileList>
                        </FormControlsBox>
                      )}
                    </>
                  )}
                </S2.FormControlsContainer>

                <CardTail line>
                  <S3.ButtonLtBox>
                    {boardId ? (
                      <>
                        <S3.StyledButton as="button" type="button" onClick={() => navigate(`${navi}${location.state?.herf || `?page=1&sort=${boardSortCode}`}`)}>
                          {t(IK.list)}
                        </S3.StyledButton>
                        {/**국가코드가 전체인 경우만 수정, 삭제 가능 */}
                        {boardAuth?.isAll && (
                          <>
                            <S3.StyledButton as="button" $secondary type="button" onClick={onDelete}>
                              {t(IK.delete)}
                            </S3.StyledButton>
                            <S3.StyledButton as="button" $primary type="submit">
                              {t(IK.modify)}
                            </S3.StyledButton>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <S3.StyledButton as="button" type="button" onClick={() => navigate(navi)}>
                          {t(IK.cancel)}
                        </S3.StyledButton>
                        <S3.StyledButton as="button" type="submit" $primary>
                          {t(IK.registration)}
                        </S3.StyledButton>
                      </>
                    )}
                  </S3.ButtonLtBox>
                </CardTail>
              </form>
            )}
          </S.CardInner>
        </S.CardCol>
      </S.CardRow>
    </>
  );
};

export default BoardPostBox;
