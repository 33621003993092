import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import {
  Alignment,
  BlockQuote,
  Bold,
  ClassicEditor,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontSize,
  Heading,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  SourceEditing,
  Strikethrough,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  WordCount,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import en from "ckeditor5/translations/en.js";
import ko from "ckeditor5/translations/ko.js";
import Notiflix from "notiflix";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IK } from "../../utils/i18n/keyStore";
import "./ckeditor.css";

/**
 * Create a free account with a trial: https://portal.ckeditor.com/checkout?plan=free
 */
const LICENSE_KEY = "GPL"; // or <YOUR_LICENSE_KEY>.

export default function TextEditor({ setData, content, setContentLength, isModal, disabled }) {
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  const { t } = useTranslation(["translation"]);
  const langInfo = localStorage.getItem("langInfo");

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const { editorConfig } = useMemo(() => {
    if (!isLayoutReady) {
      return {};
    }

    /**이미지 업로드 */
    function uploadAdapter(loader) {
      return {
        upload: async () => {
          try {
            const file = await loader.file;
            const response = await axios.post(`/api/file`, { file }, { headers: { "Content-Type": "multipart/form-data" } });
            return { default: response.data.url || response.data };
          } catch (error) {
            console.error("Image upload failed:", error);
            Notiflix.Report.failure(t(IK.file_fail), t(IK.again_message), t(IK.confirm));
            throw error; // 추가적인 에러 처리를 위해 throw
          }
        },
        abort: () => {
          console.log("Upload aborted.");
        },
      };
    }

    function uploadPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
      };
    }

    return {
      editorConfig: {
        toolbar: {
          items: isModal
            ? ["bold", "underline", "strikethrough", "italic", "|", "fontBackgroundColor", "fontColor", "fontSize", "|", "insertImage"]
            : [
                "heading",
                "|",
                "bold",
                "underline",
                "strikethrough",
                "italic",
                "|",
                "fontBackgroundColor",
                "fontColor",
                "fontSize",
                "|",
                "alignment",
                "bulletedList",
                "numberedList",
                "indent",
                "outdent",
                "|",
                "insertImage",
                "mediaEmbed",
                "link",
                "|",
                "horizontalLine",
                "blockQuote",
                "insertTable",
                "|",
                "sourceEditing",
              ],
          shouldNotGroupWhenFull: true,
        },
        plugins: [
          Alignment,
          BlockQuote,
          Bold,
          Essentials,
          FontBackgroundColor,
          FontColor,
          FontSize,
          Heading,
          HorizontalLine,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsert,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListProperties,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          SourceEditing,
          Strikethrough,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          Underline,
          WordCount,
          uploadPlugin,
        ],
        extraPlugins: [uploadPlugin],
        fontSize: {
          options: [10, 12, 14, "default", 18, 20, 22],
          supportAllValues: true,
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        image: {
          toolbar: ["toggleImageCaption", "imageTextAlternative", "|", "imageStyle:inline", "imageStyle:wrapText", "imageStyle:breakText", "|", "resizeImage"],
        },
        initialData: content ? content : "<p></p>",
        language: langInfo,
        licenseKey: LICENSE_KEY,
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: "https://",
          decorators: {
            toggleDownloadable: {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "file",
              },
            },
          },
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        mediaEmbed: {
          previewsInData: true,
        },
        placeholder: t(IK.board_placeholder),
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
        },
        translations: [ko, en],
        wordCount: {
          onUpdate: function (stats) {
            setContentLength && setContentLength({ characters: stats.characters, words: stats.words });
          },
        },
      },
    };
  }, [isLayoutReady, t, content, isModal, setContentLength, langInfo]);

  return (
    <div className="main-container">
      <div className="editor-container editor-container_classic-editor" ref={editorContainerRef}>
        <div className="editor-container__editor">
          <div ref={editorRef}>
            {isLayoutReady && editorConfig && <CKEditor key={langInfo} editor={ClassicEditor} onChange={(event, editor) => setData(editor.getData())} disabled={disabled} config={editorConfig} />}
          </div>
        </div>
      </div>
    </div>
  );
}
